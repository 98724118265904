.App {
  background-image: linear-gradient(to top, #0c1824, #07010f);
  height: 100vh;
  width: 100vw;
}

.loading-text {
  height: 90%;
}

.modal-body {
  background-color: #0d0d0d;
  color: white;
  border: 1px white solid;
}

.Footer {
  position: fixed;
  bottom: 0;
  right: 2%;
}

.stars {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
}

.stars:hover {
  cursor: pointer;
}

.star-1 {
  height: 40px;
  width: 40px;
  animation-name: twinkle-1;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 9s;
}

.star-2 {
  height: 30px;
  width: 30px;
  animation-name: twinkle-2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 9s;
}

.star-3 {
  height: 25px;
  width: 25px;
  animation-name: twinkle-3;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-delay: 9s;
}

.star-4 {
  height: 33px;
  width: 33px;
  animation-name: twinkle-4;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 9s;
}

.star-5 {
  height: 37px;
  width: 37px;
  animation-name: twinkle-5;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 9s;
}

.star-6 {
  height: 42px;
  width: 42px;
  animation-name: twinkle-6;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 9s;
}

@keyframes twinkle-1 {
  0% {
    opacity: 0.2;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes twinkle-2 {
  0% {
    opacity: 0.1;
  }
  20% {
    transform: scale(0.8);
    opacity: 0.5;
    transform: rotate(10deg);
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes twinkle-3 {
  0% {
    opacity: 0.2;
  }
  20% {
    transform: scale(0.5);
    opacity: 0.7;
    transform: rotate(20deg);
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes twinkle-4 {
  0% {
    opacity: 0.1;
  }
  20% {
    transform: scale(0.6);
    opacity: 0.4;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes twinkle-5 {
  0% {
    opacity: 0.1;
  }
  20% {
    transform: scale(0.8);
    opacity: 0.5;
    transform: rotate(90deg);
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes twinkle-5 {
  0% {
    opacity: 0.2;
  }
  20% {
    transform: scale(0.9);
    opacity: 0.8;
  }
  100% {
    opacity: 0.2;
  }
}
