#splash {
  animation: fade-in 10s linear;
  color: white;
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.2;
  }

  20% {
    opacity: 0.4;
  }

  30% {
    opacity: 0.6;
  }

  40% {
    opacity: 0.8;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 0.8;
    text-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
  }

  70% {
    opacity: 0.6;
    color: transparent;
    text-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
  }
  80% {
    opacity: 0.4;
    color: transparent;
    text-shadow: 0 0 13px rgba(0, 0, 0, 0.5);
  }
  90% {
    opacity: 0.2;
    color: transparent;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  100% {
    opacity: 0;
    color: transparent;
  }
}
